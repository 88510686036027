import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Implementing CREATE operation";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "implementing-create-operation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#implementing-create-operation",
        "aria-label": "implementing create operation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implementing CREATE operation`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#what-you-need"
          }}>{`What you need`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#1-map-http-method"
          }}>{`1. Map HTTP method`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#2-handle-parameters"
          }}>{`2. Handle parameters`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#write-a-request-converter"
            }}>{`Write a request converter`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#register-a-request-converter"
            }}>{`Register a request converter`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#3-implement-service-code"
          }}>{`3. Implement service code`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#4-return-response"
          }}>{`4. Return response`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#5-create-a-test-file"
          }}>{`5. Create a test file`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#6-register-a-serverextension"
          }}>{`6. Register a ServerExtension`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#7-test-creating-a-blog-post"
          }}>{`7. Test creating a blog post`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#next-step"
          }}>{`Next step`}</a></p>
      </li>
    </ul>
    <p>{`In this step, you'll write a service method for creating a blog post and a test method to verify the service method.
By completing this step, you'll learn to map your service with the HTTP POST (`}<a parentName="p" {...{
        "href": "type://@Post:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Post.html"
      }}>{`type://@Post`}</a>{`) method,
make your own request converter (`}<a parentName="p" {...{
        "href": "type://@RequestConverter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestConverter.html"
      }}>{`type://@RequestConverter`}</a>{`), and utilize a server extension for testing (`}<a parentName="p" {...{
        "href": "type://ServerExtension:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/ServerExtension.html"
      }}>{`type://ServerExtension`}</a>{`).`}</p>
    <TutorialSteps current={4} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`You need to have the following files obtained from previous steps.
You can always `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/blob/main/tutorials/rest-api-annotated-service/src/main/java/example/armeria/server/blog/"
      }}>{`download`}</a>{` the full version, instead of creating one yourself.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Main.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogPost.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogService.java`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "1-map-http-method",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-map-http-method",
        "aria-label": "1 map http method permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Map HTTP method`}</h2>
    <p>{`Let's start `}<a parentName="p" {...{
        "href": "/docs/server-annotated-service#mapping-http-service-methods"
      }}>{`mapping the HTTP POST method`}</a>{` with our service method:`}</p>
    <ol>
      <li parentName="ol">{`Declare a service method, `}<inlineCode parentName="li">{`createBlogPost()`}</inlineCode>{`, in the class `}<inlineCode parentName="li">{`BlogService`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Map this service method with the HTTP POST method by adding the `}<a parentName="li" {...{
          "href": "type://@Post:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Post.html"
        }}>{`type://@Post`}</a>{` annotation.`}</li>
      <li parentName="ol">{`Bind the endpoint `}<inlineCode parentName="li">{`/blogs`}</inlineCode>{` to the method.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogService.java highlight=6",
        "filename": "BlogService.java",
        "highlight": "6"
      }}>{`import com.linecorp.armeria.server.annotation.Post;

public final class BlogService {
  ...

  @Post("/blogs")
  public void createBlogPost(BlogPost blogPost) {}
}
`}</code></pre>
    <h2 {...{
      "id": "2-handle-parameters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-handle-parameters",
        "aria-label": "2 handle parameters permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Handle parameters`}</h2>
    <p>{`Let's receive blog post information through a request body. Armeria's `}<a parentName="p" {...{
        "href": "/docs/server-annotated-service#converting-an-http-request-to-a-java-object"
      }}>{`request converter`}</a>{` converts request parameters in HTTP messages into Java objects for you. In the request converter, we define what keys of a JSON object to map with what properties of a Java object.`}</p>
    <p>{`Let's first `}<a parentName="p" {...{
        "href": "#write-a-request-converter"
      }}>{`write a request converter`}</a>{` and then `}<a parentName="p" {...{
        "href": "#register-a-request-converter"
      }}>{`register the request converter`}</a>{` to the service method.`}</p>
    <h3 {...{
      "id": "write-a-request-converter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#write-a-request-converter",
        "aria-label": "write a request converter permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Write a request converter`}</h3>
    <p>{`Armeria's `}<a parentName="p" {...{
        "href": "/docs/server-annotated-service#converting-an-http-request-to-a-java-object"
      }}>{`request converter`}</a>{` converts a request body from a client into a Java object for you.`}</p>
    <p>{`We can use Armeria's default `}<a parentName="p" {...{
        "href": "type://JacksonRequestConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/JacksonRequestConverterFunction.html"
      }}>{`type://JacksonRequestConverterFunction`}</a>{` as is, but here let's give a go at customizing a request converter for our blog post requests. We want to convert blog post details into a Java object.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a `}<inlineCode parentName="p">{`BlogPostRequestConverter.java`}</inlineCode>{` file and declare a class, implementing the `}<a parentName="p" {...{
            "href": "type://RequestConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestConverterFunction.html"
          }}>{`type://RequestConverterFunction`}</a>{` interface. For the sake of simplicity, generate impromptu IDs for this tutorial.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogRequestConverter.java",
            "filename": "BlogRequestConverter.java"
          }}>{`package example.armeria.server.blog;

import com.fasterxml.jackson.databind.ObjectMapper;
import com.linecorp.armeria.server.annotation.RequestConverterFunction;
import java.util.concurrent.atomic.AtomicInteger;

final class BlogPostRequestConverter implements RequestConverterFunction {
  private static final ObjectMapper mapper = new ObjectMapper();
  private AtomicInteger idGenerator = new AtomicInteger(); // Blog post ID
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a method retrieving a value of a given key in a JSON object:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogRequestConverter.java highlight=6-12",
            "filename": "BlogRequestConverter.java",
            "highlight": "6-12"
          }}>{`import com.fasterxml.jackson.databind.JsonNode;

final class BlogPostRequestConverter implements RequestConverterFunction {
  ...

  static String stringValue(JsonNode jsonNode, String field) {
    JsonNode value = jsonNode.get(field);
    if (value == null) {
      throw new IllegalArgumentException(field + " is missing!");
    }
    return value.textValue();
  }
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Customize the default `}<inlineCode parentName="p">{`convertRequest()`}</inlineCode>{` method as follows.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogRequestConverter.java highlight=10-22",
            "filename": "BlogRequestConverter.java",
            "highlight": "10-22"
          }}>{`import com.linecorp.armeria.server.ServiceRequestContext;
import com.linecorp.armeria.common.AggregatedHttpRequest;
import com.linecorp.armeria.common.annotation.Nullable;
import java.lang.reflect.ParameterizedType;

final class BlogPostRequestConverter implements RequestConverterFunction {
  ...

  @Override
  public Object convertRequest(ServiceRequestContext ctx,
    AggregatedHttpRequest request, Class<?> expectedResultType,
    @Nullable ParameterizedType expectedParameterizedResultType)
      throws Exception {
    if (expectedResultType == BlogPost.class) {
      JsonNode jsonNode = mapper.readTree(request.contentUtf8());
      int id = idGenerator.getAndIncrement();
      String title = stringValue(jsonNode, "title");
      String content = stringValue(jsonNode, "content");
      return new BlogPost(id, title, content); // Create an instance of BlogPost object
    }
    return RequestConverterFunction.fallthrough();
  }
  ...
}
`}</code></pre>
      </li>
    </ol>
    <h3 {...{
      "id": "register-a-request-converter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#register-a-request-converter",
        "aria-label": "register a request converter permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Register a request converter`}</h3>
    <p>{`In this step, assign the `}<a parentName="p" {...{
        "href": "#write-a-request-converter"
      }}>{`request converter we customized`}</a>{` to our service method. Annotate the service method with `}<a parentName="p" {...{
        "href": "type://@RequestConverter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestConverter.html"
      }}>{`type://@RequestConverter`}</a>{` and specify the `}<a parentName="p" {...{
        "href": "type://RequestConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestConverterFunction.html"
      }}>{`type://RequestConverterFunction`}</a>{` class as `}<inlineCode parentName="p">{`BlogPostRequestConverter.class`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogService.java highlight=7",
        "filename": "BlogService.java",
        "highlight": "7"
      }}>{`import com.linecorp.armeria.server.annotation.RequestConverter;

public final class BlogService {
  ...

  @Post("/blogs")
  @RequestConverter(BlogPostRequestConverter.class)
  public void createBlogPost(BlogPost blogPost) {
    // Implement blog service
  }
}
`}</code></pre>
    <h2 {...{
      "id": "3-implement-service-code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-implement-service-code",
        "aria-label": "3 implement service code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Implement service code`}</h2>
    <p>{`When the request for creation is received, our request converter creates an instance of a blog post object for us. We want to save the blog post object in the map (`}<inlineCode parentName="p">{`blogPosts`}</inlineCode>{`) created in the `}<inlineCode parentName="p">{`BlogService`}</inlineCode>{` class.`}</p>
    <p>{`Let's store the blog post information in the map by adding line 4, in the `}<inlineCode parentName="p">{`createBlogPost()`}</inlineCode>{` method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogService.java highlight=4 showlineno=true",
        "filename": "BlogService.java",
        "highlight": "4",
        "showlineno": "true"
      }}>{`@Post("/blogs")
@RequestConverter(BlogPostRequestConverter.class)
public void createBlogPost(BlogPost blogPost) {
  blogPosts.put(blogPost.getId(), blogPost);
}
`}</code></pre>
    <h2 {...{
      "id": "4-return-response",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-return-response",
        "aria-label": "4 return response permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Return response`}</h2>
    <p>{`Now, it's time to return a response to our client. As the response, return the information received, with additional information including the ID of the post, created time, plus the modified time which would be identical to the created
time.`}</p>
    <p>{`Let's return a response for blog post creation:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Replace the return type of the `}<inlineCode parentName="p">{`createBlogPost()`}</inlineCode>{` method from `}<inlineCode parentName="p">{`void`}</inlineCode>{` to `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create and return an HTTP response using Armeria's `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{` with the information of the post created.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogService.java highlight=5,7",
            "filename": "BlogService.java",
            "highlight": "5,7"
          }}>{`import com.linecorp.armeria.common.HttpResponse;

public final class BlogService {
  ...
  public HttpResponse createBlogPost(BlogPost blogPost) {
    ...
    return HttpResponse.ofJson(blogPost);
  }
}
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "5-create-a-test-file",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#5-create-a-test-file",
        "aria-label": "5 create a test file permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Create a test file`}</h2>
    <p>{`Let's start writing test code.
We'll use test code to verify what we implement along the way.`}</p>
    <p>{`Create the `}<inlineCode parentName="p">{`BlogServiceTest.java`}</inlineCode>{` file as follows.
You can see the full version of the file `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/blob/main/tutorials/rest-api-annotated-service/src/test/java/example/armeria/server/blog/BlogServiceTest.java"
      }}>{`here`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceTest.java",
        "filename": "BlogServiceTest.java"
      }}>{`package example.armeria.server.blog;

import com.fasterxml.jackson.databind.ObjectMapper;

class BlogServiceTest {
  private static final ObjectMapper mapper = new ObjectMapper();
}
`}</code></pre>
    <h2 {...{
      "id": "6-register-a-serverextension",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#6-register-a-serverextension",
        "aria-label": "6 register a serverextension permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`6. Register a ServerExtension`}</h2>
    <p>{`Armeria's `}<a parentName="p" {...{
        "href": "type://ServerExtension:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/ServerExtension.html"
      }}>{`type://ServerExtension`}</a>{` automatically handles set-up and tear-down of a server for testing.
This is convenient as it eliminates the need to execute the main method to set up a server before running our tests.`}</p>
    <p>{`In the `}<inlineCode parentName="p">{`BlogServiceTest`}</inlineCode>{` class, register a `}<a parentName="p" {...{
        "href": "type://ServerExtension:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/ServerExtension.html"
      }}>{`type://ServerExtension`}</a>{` as follows.
Note that the service instance is added to the configuration.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceTest.java",
        "filename": "BlogServiceTest.java"
      }}>{`import org.junit.jupiter.api.extension.RegisterExtension;
import com.linecorp.armeria.server.ServerBuilder;
import com.linecorp.armeria.testing.junit5.server.ServerExtension;

class BlogServiceTest {
...
  @RegisterExtension
  static final ServerExtension server = new ServerExtension() {
    @Override
    protected void configure(ServerBuilder sb) throws Exception {
      sb.annotatedService(new BlogService());
    }
  };
}
`}</code></pre>
    <h2 {...{
      "id": "7-test-creating-a-blog-post",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#7-test-creating-a-blog-post",
        "aria-label": "7 test creating a blog post permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`7. Test creating a blog post`}</h2>
    <p>{`Let's test if we can create a blog post.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`BlogServiceTest`}</inlineCode>{` class, add a private method as follows.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`import java.util.Map;
import com.fasterxml.jackson.core.JsonProcessingException;
import com.linecorp.armeria.common.HttpRequest;
import com.linecorp.armeria.common.MediaType;
...
private static HttpRequest createBlogPostRequest(Map<String, String> content)
      throws JsonProcessingException {
  return HttpRequest.builder()
              .post("/blogs")
              .content(MediaType.JSON_UTF_8, mapper.writeValueAsString(content))
              .build();
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a test method as follows to test creating a blog post.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`import static net.javacrumbs.jsonunit.fluent.JsonFluentAssert.assertThatJson;
import org.junit.jupiter.api.Test;
import com.linecorp.armeria.client.WebClient;
import com.linecorp.armeria.common.AggregatedHttpResponse;
...
@Test
void createBlogPost() throws JsonProcessingException {
    final WebClient client = WebClient.of(server.httpUri());
    final HttpRequest request = createBlogPostRequest(Map.of("title", "My first blog",
              "content", "Hello Armeria!"));
    final AggregatedHttpResponse res = client.execute(request).aggregate().join();

    final Map<String, Object> expected = Map.of("id", 0,
              "title", "My first blog",
              "content", "Hello Armeria!");

    assertThatJson(res.contentUtf8()).whenIgnoringPaths("createdAt", "modifiedAt")
              .isEqualTo(mapper.writeValueAsString(expected));
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run the test case on your IDE or using Gradle.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`./gradlew test
`}</code></pre>
        <p parentName="li">{`The service worked as expected if you see the test case passed.`}</p>
      </li>
    </ol>
    <p>{`You can test this also with Armeria's `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`Documentation service`}</a>{`. See `}<a parentName="p" {...{
        "href": "/tutorials/rest/blog/add-services-to-server#using-docservice-after-adding-service-methods"
      }}>{`Using DocService after adding service methods`}</a>{` for instructions.`}</p>
    <h2 {...{
      "id": "next-step",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#next-step",
        "aria-label": "next step permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next step`}</h2>
    <p>{`In this step, we've written a method to implement a CREATE operation and used Armeria's annotations; `}<a parentName="p" {...{
        "href": "type://@Post:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Post.html"
      }}>{`type://@Post`}</a>{` and `}<a parentName="p" {...{
        "href": "type://@RequestConverter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestConverter.html"
      }}>{`type://@RequestConverter`}</a>{`.
We've also registered `}<a parentName="p" {...{
        "href": "type://ServerExtension:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/ServerExtension.html"
      }}>{`type://ServerExtension`}</a>{` to our test and written a test method.`}</p>
    <p>{`Next, at `}<a parentName="p" {...{
        "href": "/tutorials/rest/blog/implement-read"
      }}>{`Step 5. Implement READ`}</a>{`, we'll implement a READ operation to read a
single post and also multiple posts.`}</p>
    <TutorialSteps current={4} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      